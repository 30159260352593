
import { Component, Mixins } from 'vue-property-decorator';
import { ConfigurationTab, FullJSONTab, NotesTab, TaskListingExpandableTab } from '@/types';

import HeaderInfosMixin from '../header-infos';
import ConfigurationDocMixin from '@/mixins/data-operations/doc/configuration-doc-mixin';
import { CONFIGURATIONS } from '@/constants/data-operations/status';

import { fileUtilitiesConfs } from '@/store/modules/easy-firestore/file-utilities-confs';
import { storageToStorageConfsArchive } from '@/store/modules/easy-firestore/storage-to-storage-confs-archive';
import { TaskListingExpandableProps } from '@/components/data-operations/common/item/tasks/TaskListingExpandable.vue';

@Component
export default class FileUtilitiesConfigurationsItemView extends Mixins(HeaderInfosMixin, ConfigurationDocMixin) {
	moduleName: string = fileUtilitiesConfs.moduleName;
	archivedConfsModuleName: string = storageToStorageConfsArchive.moduleName;

	get itemTabsItems(): [ConfigurationTab, TaskListingExpandableTab, FullJSONTab, NotesTab] | [] {
		if (Object.keys(this.item).length === 0) return [];
		let tab = [this.configurationTab, this.fullJSONTab, this.notesTab] as any;
		if (this.item.tasks && this.item.tasks.length) {
			const taskTab = {
				label: 'Tasks',
				href: 'tasks',
				component: {
					name: 'task-listing-expandable',
					props: {
						tasks: this.formatTasks(this.item.tasks),
					},
				},
			};
			tab.splice(2, 0, taskTab);
		}
		return tab;
	}

	get configurationData() {
		return [
			{
				component: 'view-header',
				props: {
					item: this.item,
					collection: this.moduleName,
					activeHeader: true,
					viewId: this.item.id,
					viewType: 'conf',
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Context',
					tooltip: true,
					description: 'Context of the Storage to Storage configuration',
					paramItems: [
						{ id: 'account', label: 'Account', value: this.item.account },
						{ id: 'environment', label: 'Environment', value: this.item.environment },
						{ id: 'client_type', label: 'Client Type', value: this.getFormattedClientType(this.item.client_type) },
						{
							id: 'client_version',
							label: 'Client Version',
							value: this.getFormattedClientVersion(this.item.client_version),
						},
						{
							id: 'gcp_project_id',
							label: 'GCP Project',
							value: this.item.gcp_project_id,
						},
						{
							id: 'gcs_bucket',
							label: 'GCS Bucket',
							value: this.item.gcs_bucket,
							linkPath: `https://console.cloud.google.com/storage/browser/${this.item.gcs_bucket}?project=${this.item.gcp_project_id}`,
						},
						{
							id: 'gcs_path',
							label: 'GCS Path',
							value: this.item.gcs_path,
						},
						{
							id: 'gcs_destination_suffix',
							label: 'Destination Suffix',
							value: this.item.gcs_destination_suffix,
						},
					],
				},
			},
			{
				component: 'parameters-table',
				props: {
					tableTitle: 'File Name Template(s)',
					description:
						'Templates of the incomming files that should be copied to the destination storages. Do not put the date/timestamp prefix file in the template',
					columns: [
						{
							label: 'File Name Template',
							field: 'filename_template',
						},
						{
							label: 'File Name Description',
							field: 'file_description',
						},
					],
					rows: this.item.filename_templates,
					vflexLength: 'xs12',
					lineNumbers: false,
					searchOptionsEnabled: false,
				},
			},
			{
				component: 'dag-chart',
				props: {
					task_dependencies: this.item.task_dependencies,
				},
			},
		];
	}
}
